<template>
  <v-container>
    <v-row class="mt-4">
      <v-col cols="12" sm="5" md="4">
        <v-text-field
          v-model="handle"
          label="Search by Handle"
          placeholder="eg. shank-bone"
          outlined
          @blur="lookupByHandle"
          prepend-inner-icon="search"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="5" md="4">
        <v-text-field
          v-model="search"
          label="Search by Email"
          placeholder="eg. alex@takein.com"
          outlined
          @blur="lookupByEmail"
          prepend-inner-icon="search"
        ></v-text-field>
      </v-col>

      <v-col cols="11" sm="5" md="3" v-if="false">
        <v-text-field
          v-model="name"
          label="Search by Name"
          placeholder="eg. Poke"
          outlined
          @blur="lookupByName"
          prepend-inner-icon="search"
        ></v-text-field>
      </v-col>
      <v-col cols="1"
        ><v-btn
          icon
          @click="
            search = null
            name = null
            handle = null
            filterList = null
          "
          ><v-icon>clear</v-icon>
        </v-btn></v-col
      >
    </v-row>
    <v-row class="mt-0">
      <v-col cols="6" sm="4">
        <v-radio-group
          v-model="profileType"
          row
          @change="changeType()"
          class="ma-0"
        >
          <v-radio
            color="light-blue"
            v-for="profile in profileTypeList"
            :key="profile"
            :label="profile.charAt(0).toUpperCase() + profile.slice(1)"
            :value="profile"
          ></v-radio>
        </v-radio-group>
      </v-col>
    </v-row>

    <v-row class="mt-2" v-if="filterList && filterList.length > 0">
      <v-col cols="12">
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  Email
                </th>
                <th class="text-left">
                  Name
                </th>
                <th class="text-left">
                  Handle
                </th>
                <th class="text-left">
                  Type
                </th>
                <th class="text-left">
                  Status
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in filterList" :key="item.name">
                <td>{{ item.email }}</td>
                <td>{{ item.name }}</td>
                <td>
                  <router-link :to="'profile/' + item.uid">{{
                    item.handle
                  }}</router-link>
                </td>
                <td>{{ item.type }}</td>
                <td>{{ item.profileStatus }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
    <v-row v-else class="mt-2">
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="userList"
          :loading="loading"
          class="elevation-1"
        >
          <!-- producer status  -->
          <template
            v-if="profileType == 'producer'"
            v-slot:item.approvalStatus="{ item }"
          >
            <span
              dark
              :class="getStatusColor(item.approvalStatus) + '--text'"
              >{{ item.approvalStatus ? item.approvalStatus : 'pending' }}</span
            >
          </template>

          <!-- approval action -->
          <template
            v-if="profileType == 'producer'"
            v-slot:item.approve="{ item }"
          >
            <v-icon
              v-if="item.approvalStatus != 'approved'"
              color="green"
              class="ml-4"
              @click="showApprovalDialog(item.id, item.approvalStatus)"
              >check</v-icon
            >
            <v-icon
              v-else-if="item.approvalStatus === 'approved'"
              color="red"
              class="ml-4"
              @click="showApprovalDialog(item.id, item.approvalStatus)"
              >pan_tool</v-icon
            >
          </template>

          <!-- time when chef was created -->
          <template
            v-if="profileType == 'producer'"
            v-slot:item.created="{ item }"
          >
            {{ timeCreated(item.created) }}
          </template>

          <!-- link to producer profile -->
          <!-- NEED TO EDIT PROFILE PERMISSIONS -->
          <template v-slot:item.id="{ item }">
            <router-link :to="'/admin/profile/' + item.id">{{
              item.id
            }}</router-link>
          </template>

          <!-- producer service fee -->
          <template
            v-if="profileType == 'producer'"
            v-slot:item.producer_service_fee_pct="{ item }"
          >
            <v-chip
              dark
              :color="getFeeColor(item.producer_service_fee_pct)"
              @click=";(showFeeDialog = true), (userId = item.id)"
            >
              {{
                item.producer_service_fee_pct
                  ? (item.producer_service_fee_pct * 100).toFixed(2)
                  : serviceFee
                  ? serviceFee.toFixed(2)
                  : 0
              }}
              %
            </v-chip>
          </template>

          <!-- message -->
          <template v-slot:item.message="{ item }">
            <v-icon color="grey" @click="messageUser(item.id)">message</v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <!-- chef info dialog -->
    <!-- <v-dialog v-model="showChefInfoDialog" width="500px">
      <v-card>
        <v-card-title class="py-4">
          <v-icon color="grey" large>person</v-icon>
          <h4 class="ml-4">Chef Info</h4>
        </v-card-title>
        <v-card-text>
          <table style="width:100%">
            <tr>
              <td>
                <span class="subtitle-1 font-weight-medium">Name:</span>
              </td>
              <td>
                <span class="subtitle-1">{{ chefObj.name }}</span>
              </td>
            </tr>
            <tr>
              <td>
                <span class="subtitle-1 font-weight-medium">Email:</span>
              </td>
              <td>
                <span class="subtitle-1">{{ chefObj.email }}</span>
              </td>
            </tr>
            <tr>
              <td>
                <span class="subtitle-1 font-weight-medium">Dishes:</span>
              </td>
              <td>
                <span class="subtitle-1">{{ chefObj.dishes }}</span>
              </td>
            </tr>

            <tr>
              <td>
                <span class="subtitle-1 font-weight-medium">Profile Status:</span>
              </td>
              <td>
                <span class="subtitle-1">{{ chefObj.profileStatus }}</span>
              </td>
            </tr>
            <tr>
              <td>
                <span class="subtitle-1 font-weight-medium">Last Updated:</span>
              </td>
              <td>
                <span class="subtitle-1">{{ chefObj.updatedTime }}</span>
              </td>
            </tr>
            <v-div
              :v-if="chefObj.permits"
              v-for="permit in chefObj.permits"
              :key="permit.id"
              class="ma-0 pa-0"
            >
              <tr>
                <td>
                  <span class="subtitle-1 font-weight-medium">Permit Name:</span>
                </td>
                <td>
                  <span class="subtitle-1">{{ permit.name }}</span>
                </td>
              </tr>
              <tr>
                <td>
                  <span class="subtitle-1 font-weight-medium">Type:</span>
                </td>
                <td>
                  <span class="subtitle-1">{{ permit.type }}</span>
                </td>
              </tr>
              <tr>
                <td>
                  <span class="subtitle-1 font-weight-medium">ID:</span>
                </td>
                <td>
                  <span class="subtitle-1">{{ permit.id }}</span>
                </td>
              </tr>
              <tr>
                <td>
                  <span class="subtitle-1 font-weight-medium">Authority:</span>
                </td>
                <td>
                  <span class="subtitle-1">{{ permit.authority }}</span>
                </td>
              </tr>
            </v-div>
          </table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="showChefInfoDialog = false, chefObj = {}">Ok</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>-->

    <!-- chef approval dialog -->
    <v-dialog v-model="showApproveDialog" width="500px">
      <v-card>
        <v-card-title class="py-4">
          <v-icon color="grey" large>how_to_reg</v-icon>
          <h4 class="ml-4" v-if="newStatus != 'approved'">Approve Producer?</h4>
          <h4 class="ml-4" v-else>Suspend Producer?</h4>
        </v-card-title>
        <v-card-subtitle>
          <span>{{ EDIT_DIALOG_WARNING }}</span>
        </v-card-subtitle>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="showApproveDialog = false">Cancel</v-btn>
          <v-btn
            v-if="newStatus != 'approved'"
            color="blue"
            dark
            @click="updateProducer('approvalStatus', 'approved')"
            >Approve</v-btn
          >
          <v-btn
            v-else
            color="red"
            dark
            @click="updateProducer('approvalStatus', 'suspend')"
            >Suppend</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- producer service fee dialog -->
    <v-dialog v-model="showFeeDialog" width="500px">
      <v-card>
        <v-card-title class="py-4">
          <v-icon color="grey" large>how_to_reg</v-icon>
          <h4 class="ml-4">Edit Producer Service Fee</h4>
        </v-card-title>
        <v-card-subtitle>
          <span>{{ EDIT_DIALOG_WARNING }}</span>
        </v-card-subtitle>
        <v-card-text>
          <v-text-field
            v-model="newServiceFee"
            label="New Service Fee"
            outlined
            type="number"
            suffix="%"
            :rules="[rules.required, rules.positive, rules.hundred]"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="showFeeDialog = false">Cancel</v-btn>
          <v-btn color="blue" dark @click="verifyFee">Edit</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- email dialog -->
    <v-dialog v-model="showEmailDialog" width="500px">
      <v-card>
        <v-form ref="form" v-model="emailIsValid" :lazy-validation="false">
          <v-card-title class="py-4">
            <v-icon color="grey" large>email</v-icon>
            <h4 class="ml-4">Send User an Email</h4>
          </v-card-title>
          <v-card-subtitle>
            <span>{{ MESAGE_WARNING }}</span>
          </v-card-subtitle>
          <v-card-text>
            <v-textarea
              v-model="emailMessage"
              clearable
              auto-grow
              label="Message"
              outlined
              rows="5"
              :rules="[rules.required]"
            ></v-textarea>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="showEmailDialog = false">Cancel</v-btn>
            <v-btn color="blue" :disabled="!emailIsValid" @click="sendEmail"
              >Send</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'

export default {
  data() {
    return {
      search: null,
      name: null,
      handle: null,
      type: null,
      headers: [
        {
          text: 'User',
          align: 'left',
          value: 'name'
        },
        { text: 'ID', value: 'id' },
        { text: 'Approval Status', value: 'approvalStatus' },
        { text: 'Approve', value: 'approve', sortable: false },
        { text: 'Created at', value: 'created' },
        {
          text: 'Producer Service Fee',
          value: 'producer_service_fee_pct',
          align: 'center',
          sortable: false
        },
        {
          text: 'Message',
          value: 'message',
          sortable: false
        }
      ],
      userHeaders: [
        {
          text: 'Email',
          align: 'left',
          value: 'email'
        },
        {
          text: 'Name',
          align: 'left',
          value: 'name'
        },
        { text: 'ID', value: 'id' },
        { text: 'Type', value: 'type' }
      ],
      showChefInfoDialog: false,
      showApproveDialog: false,
      userId: null,
      newStatus: null,
      userList: [],
      filterList: null,
      chefObj: {},
      showFeeDialog: false,
      EDIT_DIALOG_WARNING:
        'Changing this value will impact this producer and respective customers. Please make sure the information is accurate.',
      MESAGE_WARNING:
        'This message will directly be sent to the producer via email. Please make sure the content is appropriate.',
      newServiceFee: null,
      rules: {
        required: value => !!value || 'Value Required',
        positive: value => value >= 0 || 'Value Must Be Positive',
        hundred: value => value <= 100 || 'Value Must Be Less Than 100'
      },
      showEmailDialog: false,
      emailIsValid: false,
      emailMessage: null,
      profileType: 'producer',
      profileTypeList: ['producer', 'customer']
    }
  },
  computed: {
    ...mapGetters({
      adminProducers: 'adminProducers',
      serviceFee: 'serviceFee',
      loading: 'loading',
      profiles: 'profiles',
      user: 'user'
    })
  },
  watch: {},
  created() {
    console.log('created-x')
    this.loadUsers()
  },
  mounted() {
    console.log('mounted-x')
    this.loadUsers()
  },
  methods: {
    loadUsers() {
      // load both producers and profiles
      this.$store.dispatch('getAdminProducers').then(() => {
        const type = 'customer'
        this.$store.dispatch('getProfiles', { type }).then(() => {
          this.userList = this.adminProducers
          // get standard service fee
          this.$store.dispatch('getOrderFees')
        })
      })
    },
    lookupByHandle() {
      if (this.handle) {
        this.filterList = []
        this.$store.dispatch('getProfileByHandle', this.handle).then(d => {
          if (d === null) {
            this.$store.dispatch('setMessage', {
              title: 'Not found',
              body: `Handle ${this.handle} not found`
            })
            return
          }
          if (d) {
            this.filterList.push(d)
          }
        })
        // search chefs based on name
      }
    },
    lookupByEmail() {
      if (this.search) {
        this.$store.dispatch('getProfiles', { email: this.search }).then(d => {
          this.filterList = d
        })
        // search chefs based on name
      }
    },
    lookupByName() {
      this.profileType === 'producer' ? this.adminProducers : this.profiles
      if (this.name) {
        this.$store.dispatch('getProfiles', { name: this.name }).then(d => {
          this.filterList = d
        })
        // search chefs based on name
      }
    },
    getStatusColor(status) {
      if (!status) {
        return 'red'
      }
      if (status == 'approved') {
        return 'green'
      }
      if (status == 'suspend') {
        return 'red'
      }
      return 'blue'
    },
    getFeeColor(feePer) {
      // return color based on fee percetnage
      if (feePer <= 0.15 || !feePer) {
        return 'green'
      } else if (feePer < 0.3) {
        return 'yellow darken-3'
      } else {
        return 'red'
      }
    },
    updateProducer(type, status = 'approved') {
      if (!this.userId || !type) {
        alert('Error, Please Refresh the Page')
        return
      }

      // chef id set to null to avoid id clash with chef info
      let id = this.userId
      this.userId = null

      // configure update by type
      let change = ''
      if (type === 'approvalStatus') {
        console.log('Approving chef:', id)
        change = status
        this.showApproveDialog = false
      } else if (type === 'producer_service_fee_pct') {
        console.log('Updating Producer Service Fee', id)
        change = this.newServiceFee / 100
        this.showFeeDialog = false
      }

      let payload = {
        [type]: change,
        type: 'chefs',
        id: id
      }

      this.$store.dispatch('updateAccount', payload).then(() => {
        console.log('Successfully Updated')
        this.$store.dispatch('getAdminProducers', { type }).then(() => {
          this.userList = this.adminProducers
        })
        // update current chef list
        let index = this.adminProducers.findIndex(chef => chef.id === id)
        let chefObj = { ...this.adminProducers[index], ...{ [type]: change } }
        this.userList.splice(index, 1, chefObj)
      })
    },
    timeCreated(time) {
      if (time) {
        return moment.unix(time / 1000).format('DD MMM YYYY')
      } else {
        return 'N/A'
      }
    },
    verifyFee() {
      if (!this.newServiceFee) {
        alert('Service Fee Cannot Be Empty!')
      } else if (this.newServiceFee < 0) {
        alert('Service Fee Cannot Be Less Than 0.')
      } else if (this.newServiceFee > 100) {
        alert('Service Fee Cannot Be More Than 100.')
      } else {
        this.updateProducer('producer_service_fee_pct')
      }
    },
    showApprovalDialog(id, status) {
      this.showApproveDialog = true
      this.userId = id
      this.newStatus = status
    },
    changeType() {
      if (this.profileType == 'producer') {
        this.userList = this.adminProducers
      } else if (this.profileType == 'customer') {
        this.userList = this.profiles
      }
    },
    messageUser(id) {
      this.userId = id
      this.showEmailDialog = true
    },
    sendEmail() {
      console.log(this.profile)
      let payload = {
        message: this.emailMessage,
        recipient_id: this.userId,
        sender_id: this.user.id
      }
      this.userId = null
      this.emailMessage = null
      this.showEmailDialog = false
      this.$store.dispatch('messageUser', payload)
    }
  }
}
</script>
